var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-user-sidebar","visible":_vm.isAddNewUserSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-add-new-user-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_c('strong',[_vm._v("AGREGAR NUEVO PROVEEDOR")])]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('validation-provider',{attrs:{"name":"nombre(s)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Nombre del proveedor","label-for":"names"}},[_c('b-form-input',{attrs:{"id":"names","state":_vm.getValidationState(validationContext),"trim":""},model:{value:(_vm.userData.name),callback:function ($$v) {_vm.$set(_vm.userData, "name", $$v)},expression:"userData.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|email","name":"correo electrónico principal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"email"}},[_vm._v("Correo electrónico "),_c('strong',{staticClass:"text-primary"},[_vm._v("(principal)")])]),_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),(errors.length)?_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('small',{staticClass:"text-primary d-block mb-2"},[_vm._v("Este correo será utilizado para iniciar sesión, notificaciones principales y notificaciones secundarias.")]),_c('validation-provider',{attrs:{"rules":"email","name":"correo electrónico secundario"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"secondary_email"}},[_vm._v("Correo electrónico "),_c('strong',{staticClass:"text-primary"},[_vm._v("(secundario)")])]),_c('b-form-input',{attrs:{"id":"secondary_email","state":errors.length > 0 ? false:null,"trim":""},model:{value:(_vm.userData.secondary_email),callback:function ($$v) {_vm.$set(_vm.userData, "secondary_email", $$v)},expression:"userData.secondary_email"}}),(errors.length)?_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('small',{staticClass:"text-primary d-block mb-2"},[_vm._v("Este correo será utilizado adicionalmente para notificaciones secundarias (Actualización de credenciales, Código de seguridad al iniciar sesión y Enlace para recuperar contraseña).")]),_c('validation-provider',{attrs:{"name":"ruc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"RUC","label-for":"ruc"}},[_c('b-form-input',{attrs:{"id":"ruc","state":_vm.getValidationState(validationContext),"trim":""},model:{value:(_vm.userData.ruc),callback:function ($$v) {_vm.$set(_vm.userData, "ruc", $$v)},expression:"userData.ruc"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"rubro","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Seleccionar Rubro","label-for":"business_areas"}},[_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Seleccione","label":"name","options":_vm.business_areas},on:{"input":function($event){return _vm.onChangeBusinessArea($event)}},model:{value:(_vm.userData.business_area),callback:function ($$v) {_vm.$set(_vm.userData, "business_area", $$v)},expression:"userData.business_area"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"tarifario"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.tariff_allowed)?_c('b-form-group',{attrs:{"label":"Seleccionar Tarifario","label-for":"tarifario_id"}},[_c('v-select',{attrs:{"id":"tarifario_id","state":errors.length > 0 ? false:null,"placeholder":"Seleccione","label":"type","options":_vm.tariffs},model:{value:(_vm.userData.tariff),callback:function ($$v) {_vm.$set(_vm.userData, "tariff", $$v)},expression:"userData.tariff"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1):_vm._e()]}}],null,true)}),_c('validation-provider',{attrs:{"name":"fecha inicio del ciclo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Fecha de inicio del Ciclo","label-for":"start_date_cycle"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"start_date_cycle","state":errors.length > 0 ? false:null,"config":{ enableTime: false, dateFormat: 'd-m-Y', minDate:'today', disable: ['today']},"locale":"es"},model:{value:(_vm.userData.start_date_cycle),callback:function ($$v) {_vm.$set(_vm.userData, "start_date_cycle", $$v)},expression:"userData.start_date_cycle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Número(s) de contrato(s)","label-for":"contracts"}},[_c('b-form-input',{attrs:{"id":"contracts","trim":""},model:{value:(_vm.userData.contract_numbers),callback:function ($$v) {_vm.$set(_vm.userData, "contract_numbers", $$v)},expression:"userData.contract_numbers"}})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" AGREGAR ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" CANCELAR ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }