<template>

    <div>
        
        <b-card>
			<b-row>					
				<b-col cols="12" class="mb-1">
					<h5>FILTROS</h5>
				</b-col>
				
				<b-col sm="12" md="6">
					<label>Rubro(s)</label>
					<v-select 
                        multiple
					    v-model="filterBusinessArea"
					    :options="optionsBusinessAreas" />
				</b-col>
				<b-col sm="12" md="6">
					<label>Estado(s) del último formulario</label>
					<v-select 
                        multiple
					    v-model="filterState"
					    :options="optionsStates" />
				</b-col>                  
			</b-row>
		</b-card>

        <!-- Table Container Card -->
        <b-card
            no-body
            class="mb-0">

            <div class="m-2">

                <!-- Table Top -->
                <b-row align-h="between">

                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="3"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Mostrar</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label>registros</label>
                    </b-col>

                    <!-- Search -->
                    <b-col
                        cols="12"
                        md="6"
                    >
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Buscar ..."
                            />
                            <b-button
                                v-if="['superadmin'].includes(role)"                                  
                                @click="isAddNewPositionSidebarActive = true"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="primary"
                                class="mr-1"
                            >
                                <span class="text-nowrap">Cargar Puestos</span>    
                            </b-button>
                            <b-button
                                variant="primary"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                @click="isAddNewUserSidebarActive = true"
                            >
                                <span class="text-nowrap">Agregar Proveedor</span>                                
                            </b-button>
                        </div>
                    </b-col>
                </b-row>

            </div>

            <b-table
                ref="refProviderListTable"
                class="position-relative"
                :style="totalUsers < 3 && totalUsers > 0 ? 'height: 250px' : 'height: inherit'"
                :items="fetchProviders"
                responsive
                :fields="tableColumns"
                primary-key="id2"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No se encontraron registros coincidentes"
                :sort-desc.sync="isSortDirDesc"
            >

                <!-- Column: Provider -->
                <template #cell(user.names)="data">
                    <b-media vertical-align="center">
                        <b-link
                            :to="{ name: 'providers-edit', params: { id: data.item.id } }"
                            class="font-weight-bold d-block text-nowrap">
                            {{ data.item.user.names }}
                        </b-link>
                    </b-media>
                </template>
                
                <template #cell(business_area.name)="data">
                    <b-col>
                        {{ data.item.business_area.name }}
                    </b-col>
                </template>

                <template #cell(contract_numbers)="data">
                    <b-col style="width: 100px">
                        {{ data.item.contract_numbers ? data.item.contract_numbers : '---' }}
                    </b-col>                    
                </template>
                
                <template #cell(start_date_cycle)="data">
                    <b-col style="width: 150px">
                        {{ moment(data.item.start_date_cycle).format('DD-MM-YYYY') }}
                    </b-col>                    
                </template>
                
                <template #cell(forms_count)="data">
                    {{ data.item.forms[0] ? data.item.forms[0].forms_count : 0 }}                 
                </template>
                
                <template #cell(last_form)="data">
                    <template v-if="data.item.forms.length > 0">         
                        {{ lastFormFormat(data.item.forms[0].start_date) }}	
                    </template>
                    <template v-else>
                        ---
                    </template>
                </template>
                                
                <template #cell(forms.state.name)="data">
                    <template v-if="data.item.forms.length > 0">         
                        <b-badge 
                            :variant="`light-${data.item.forms[0].state.color}`"
                            >
                            {{ data.item.forms[0].state.name }}
                        </b-badge>	
                    </template>
                    <template v-else>
                        ---
                    </template>
                </template>

                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>

                        <b-dropdown-item :to="{ name: 'providers-forms', params: { id: data.item.id } }">
                            <feather-icon icon="ClipboardIcon" />
                            <span class="align-middle ml-50">Formularios</span>
                        </b-dropdown-item>
                        
                        <b-dropdown-item :to="{ name: 'providers-edit', params: { id: data.item.id } }">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Editar</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="deleteUser(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Eliminar</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>

            </b-table>
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
                    </b-col>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">

                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalUsers"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item">

                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
        </b-card>

        <provider-list-add-new
            :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
            @refetch-data="refetchData"
        />
        
        <positions-import-file
            :is-add-new-position-sidebar-active.sync="isAddNewPositionSidebarActive"
        />
        
    </div>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { onUnmounted, ref, reactive } from '@vue/composition-api';
    import PositionsImportFile from './PositionsImportFile.vue';
    import { useToast } from 'vue-toastification/composition';
    import providerStoreModule from '../providerStoreModule';
    import ProviderListAddNew from './ProviderListAddNew';
    import useProvidersList from './useProvidersList';
    import VueSweetalert2 from 'vue-sweetalert2';
    import Ripple from 'vue-ripple-directive';
    import vSelect from 'vue-select';
    import store from '@/store';
    import moment from 'moment';
    import axios from '@axios';
    import Vue from 'vue';
    import 'animate.css';
    
    Vue.use(VueSweetalert2);

    export default {
        components: {
            ProviderListAddNew,
            PositionsImportFile,

            vSelect,
            
            ValidationProvider,
            ValidationObserver
        },
        directives: {
            Ripple
        },
        data () {                                   
            return {
                moment,
                role: JSON.parse(localStorage.getItem('userData')).role
            }
        },
        methods: {
            lastFormFormat (date){
                const year = moment(date).format('YYYY')
                const month = moment(date).format('M')
                
                let monthName = '';
  
                switch (month) {
                    case '1':
                        monthName = 'ENERO';
                        break;

                    case '2':
                        monthName = 'FEBRERO';
                        break;

                    case '3':
                        monthName = 'MARZO';
                        break;

                    case '4':
                        monthName = 'ABRIL';
                        break;

                    case '5':
                        monthName = 'MAYO';
                        break;

                    case '6':
                        monthName = 'JUNIO';
                        break;

                    case '7':
                        monthName = 'JULIO';
                        break;

                    case '8':
                        monthName = 'AGOSTO';
                        break;

                    case '9':
                        monthName = 'SEPTIEMBRE';
                        break;

                    case '10':
                        monthName = 'OCTUBRE';
                        break;

                    case '11':
                        monthName = 'NOVIEMBRE';
                        break;

                    default:
                        monthName = 'DICIEMBRE';
                }

                return `${monthName}, ${year}`
            }
        },
        setup() {

            // Use toast
            const toast = useToast();

            const USER_APP_STORE_MODULE_NAME = 'app-provider';

            // Register module
            if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, providerStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
            });
                        
            const isAddNewUserSidebarActive = ref(false);
            const isAddNewPositionSidebarActive = ref(false);
            
            const optionsBusinessAreas = reactive([]);
            
            axios.get('/business-area/select')
            .then(response => {
                response.data.map( item => {
					optionsBusinessAreas.push({
						label : item.name, 
						value : item.id
					});
				});
            });
            
            const optionsStates = reactive([]);
            
            axios.get('/state/select')
            .then(response => {
                 response.data.map( item => {
					optionsStates.push({
						label : item.name, 
						value : item.id
					});
				});
            });
            
            const {
                fetchProviders,
                tableColumns,
                perPage,
                currentPage,
                totalUsers,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refProviderListTable,
                refetchData,
                
                filterBusinessArea,
                filterState
            } = useProvidersList();
            
            const deleteUser = (id) => {
                Vue.swal({
                    title: '¿Estás seguro de eliminar al proveedor?',
                    text: "¡Si no lo está, puede cancelar la acción!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sí, eliminar!',
                    cancelButtonText: 'Cancelar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    showClass: {
                        popup: 'animate__animated animate__tada'
                    },
                    buttonsStyling: false
                }).then(result => {
                    if (result.value) {
                        store.dispatch('app-provider/deleteProvider', { id })
                            .then( (response) => {
                                refetchData();
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: 'CheckIcon',
                                        variant: 'success'
                                    }
                                });
                            })
                            .catch( () => {
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Error al eliminar el proveedor',
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger'
                                    }
                                });
                            });
                    }
                });
            };

            return {
                // Sidebar
                isAddNewUserSidebarActive,            
                isAddNewPositionSidebarActive,

                fetchProviders,
                tableColumns,
                perPage,
                currentPage,
                totalUsers,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refProviderListTable,
                refetchData,
                
                // Filters
                optionsBusinessAreas,
                optionsStates,
                filterBusinessArea,
                filterState,

                // Methods
                deleteUser
            }
        }
    }

</script>

<style lang="scss" scoped>

    .per-page-selector {
        width: 90px;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>