import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useProvidersList() {

    // Use toast
    const toast = useToast();

    const refProviderListTable = ref(null);

    // Table Handlers
    let tableColumns = [
        { key: 'user.names', sortable: true, label: 'Proveedor' },
        { key: 'ruc', sortable: true, label: 'RUC' },
        { key: 'business_area.name', sortable: true, label: 'Rubro' },
        { key: 'user.email', sortable: true, label: 'Correo electrónico' },
        { key: 'contract_numbers', sortable: true, label: 'Número(s) de contrato(s)' },
        { key: 'start_date_cycle', sortable: true, label: 'Fecha de Inicio de ciclo' },
        { key: 'forms_count', sortable: true, label: 'Cantidad de formularios asignados' },
        { key: 'last_form', sortable: false, label: 'Último formulario asignado' },
        { key: 'forms.state.name', sortable: false, label: 'Último estado de formulario' },
        { key: 'actions', label: 'Opciones' }
    ];

    const perPage = ref(10);
    const totalUsers = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(false);
    const filterBusinessArea = ref(null);
    const filterState = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refProviderListTable.value ? refProviderListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalUsers.value,
        }
    });

    const refetchData = () => {
        refProviderListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, filterBusinessArea, filterState], () => {
        refetchData();
    });

    const fetchProviders = (ctx, callback) => {

        let filterBusinessAreaArray = [];
        if (filterBusinessArea.value) {
            filterBusinessArea.value.map(item => {
                filterBusinessAreaArray.push(item.value);
            });
        }

        let filterStateArray = [];
        if (filterState.value) {
            filterState.value.map(item => {
                filterStateArray.push(item.value);
            });
        }
                
        store
            .dispatch('app-provider/fetchProviders', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                filterBusinessArea: filterBusinessArea.value ? filterBusinessAreaArray.join(',') : null,
                filterState: filterState.value ? filterStateArray.join(',') : null
            })
            .then(response => {
                const { users, total } = response.data;
                callback(users);
                totalUsers.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista de proveedores',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                });
            })
    };

    return {
        fetchProviders,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refProviderListTable,

        refetchData,

        // Extra Filters
        filterBusinessArea,
        filterState
    }
}