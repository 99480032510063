<template>
    <b-sidebar
        id="add-new-user-sidebar"
        :visible="isAddNewUserSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="resetForm"
        @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)">
        <template #default="{ hide }">
                       
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    <strong>AGREGAR NUEVO PROVEEDOR</strong>
                </h5>

                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />

            </div>

            <!-- BODY -->
            <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
            >
                <!-- Form -->
                <b-form
                    class="p-2"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >

                    <!-- Name -->
                    <validation-provider
                        #default="validationContext"
                        name="nombre(s)"
                        rules="required"
                    >
                        <b-form-group
                            label="Nombre del proveedor"
                            label-for="names"
                        >
                            <b-form-input
                                id="names"
                                v-model="userData.name"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Main Email -->
                    <validation-provider
                        #default="{ errors }"
                        rules="required|email"
                        name="correo electrónico principal"
                    >
                        <label for="email">Correo electrónico <strong class="text-primary">(principal)</strong></label>
                        <b-form-input
                            id="email"
                            v-model="userData.email"
                            :state="errors.length > 0 ? false:null"
                            trim
                        />
                        <small class="text-danger d-block" v-if="errors.length">{{ errors[0] }}</small>
                    </validation-provider>
                    <small class="text-primary d-block mb-2">Este correo será utilizado para iniciar sesión, notificaciones principales y notificaciones secundarias.</small>
                    

                    <!-- Secondary Email -->
                    <validation-provider
                        #default="{ errors }"
                        rules="email"
                        name="correo electrónico secundario"
                    >
                        <label for="secondary_email">Correo electrónico <strong class="text-primary">(secundario)</strong></label>
                        <b-form-input
                            id="secondary_email"
                            v-model="userData.secondary_email"
                            :state="errors.length > 0 ? false:null"
                            trim
                        />
                        <small class="text-danger d-block" v-if="errors.length">{{ errors[0] }}</small>
                    </validation-provider>
                    <small class="text-primary d-block mb-2">Este correo será utilizado adicionalmente para notificaciones secundarias (Actualización de credenciales, Código de seguridad al iniciar sesión y Enlace para recuperar contraseña).</small>

                    
                    <!-- Ruc -->
                    <validation-provider
                        #default="validationContext"
                        name="ruc"
                        rules="required"
                    >
                        <b-form-group
                            label="RUC"
                            label-for="ruc"
                        >
                            <b-form-input
                                id="ruc"
                                v-model="userData.ruc"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    
                    <!-- Rubro -->
                    <validation-provider
                        #default="{ errors }"
                        name="rubro"
                        rules="required"
                    >
                        <b-form-group
                            label="Seleccionar Rubro"
                            label-for="business_areas"
                        >
                            <v-select
                                v-model="userData.business_area"
                                @input="onChangeBusinessArea($event)"
                                :state="errors.length > 0 ? false:null"
								placeholder="Seleccione"
                                label="name"
                                :options="business_areas"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                    </validation-provider>
                    
                    <!-- Tarifario -->
                    <validation-provider
                        #default="{ errors }"
                        name="tarifario"
                    >
                        <b-form-group
                           v-if="tariff_allowed"
                            label="Seleccionar Tarifario"
                            label-for="tarifario_id"
                        >
                            <v-select
                                id="tarifario_id"
                                v-model="userData.tariff"
                                :state="errors.length > 0 ? false:null"
								placeholder="Seleccione"
                                label="type"
                                :options="tariffs"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                    </validation-provider>
                    
                     <!-- Start date cycle -->
                    <validation-provider
                        #default="{ errors }"
                        name="fecha inicio del ciclo"
                        rules="required"
                    >
                        <b-form-group
                            label="Fecha de inicio del Ciclo"
                            label-for="start_date_cycle"
                        >
                            <flat-pickr
                                id="start_date_cycle"
                                v-model="userData.start_date_cycle"
                                :state="errors.length > 0 ? false:null"
                                class="form-control"
                                :config="{ enableTime: false, dateFormat: 'd-m-Y', minDate:'today', disable: ['today']}"                            
                                locale="es"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                    </validation-provider>


                    <!-- Contract Numbers -->
                    <b-form-group
                        label="Número(s) de contrato(s)"
                        label-for="contracts"
                    >
                        <b-form-input
                            id="contracts"
                            v-model="userData.contract_numbers"
                            trim
                        />
                    </b-form-group>
                    
                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            type="submit"
                        >
                            AGREGAR
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click="hide"
                        >
                            CANCELAR
                        </b-button>
                    </div>

                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>

    import { useToast } from 'vue-toastification/composition';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { ref } from '@vue/composition-api';
    import { required, alphaNum } from '@validations';
    import formValidation from '@core/comp-functions/forms/form-validation';
    import Ripple from 'vue-ripple-directive';
    import vSelect from 'vue-select';
    import flatPickr from 'vue-flatpickr-component'
    import store from '@/store';
    import axios from '@axios';
    import moment from 'moment';
    
    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            vSelect,
            flatPickr
        },
        directives: {
            Ripple,
        },
        model: {
            prop: 'isAddNewUserSidebarActive',
            event: 'update:is-add-new-user-sidebar-active',
        },
        props: {
            isAddNewUserSidebarActive: {
                type: Boolean,
                required: true
            }
        },
        data() {                                   
            return {
                required,
                alphaNum,
                business_areas: [],
                tariffs: [],
                tariff_allowed: false,
                MINA_ID: 1
            }
        }, 
        mounted () {
            this.getBusinessAreas()
        },
        methods: {
			async getTariffs (business_area_id) {
				const response = await axios.get(`/tariffs/select?business_area_id=${business_area_id}`);
				this.tariffs = response.data;
			},
            getBusinessAreas () {
                axios.get('/business-area/select')
                .then(response => {
                    if (response && response.data)
                        this.business_areas = response.data;
                });
            },
            onChangeBusinessArea (val) {
                if (val && val.id === this.MINA_ID) {
					this.tariff_allowed = true;					
					this.getTariffs(val.id)
					return;
				}

				this.tariff_allowed = false;
				this.userData.tariff = null;
				return;
            }
        },
        setup(props, { emit }) {

            // Use toast
            const toast = useToast();

            const blankUserData = {
                name: '',
                email: '',
                secondary_email: '',
                ruc: '',
                start_date_cycle: null,
                contract_numbers: null,        
                business_area: null,      
                tariff: null
            };

            const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
            const resetuserData = () => {
                userData.value = JSON.parse(JSON.stringify(blankUserData));
            };
            
            const onSubmit = () => {
                
                let providerData = {
                    sdcISO: moment(userData.value.start_date_cycle, "DD-MM-YYYY").toISOString(),
                    ...userData.value
                }
                
                store.dispatch('app-provider/addProvider', providerData)
                .then( (response) => {
                    emit('refetch-data');

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data.message,
                            icon: 'CheckIcon',
                            variant: 'success'
                        }
                    });

                    emit('update:is-add-new-user-sidebar-active', false);
                })
                .catch( (err) => {
                    let message = err.response.data.message ? err.response.data.message : 'Error al crear el proveedor.';
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: message,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    });
                });
            };

            const {
                refFormObserver,
                getValidationState,
                resetForm,
            } = formValidation(resetuserData);

            return {
                userData,
                onSubmit,

                refFormObserver,
                getValidationState,
                resetForm
            }
        }
    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>


<style lang="scss">

    #add-new-user-sidebar {
        .vs__dropdown-menu {
            max-height: 200px !important;
        }
    }

</style>
